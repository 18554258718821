<template>
  <v-container class="mt-6">
    <!-- ROW#1 -->
    <div class="subHeader">Special circumstances</div>
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(ernStudentData.anyAdditionalInforPriorEnrol)"
          label="Any special circumstances about the student that the school should know prior to enrolment?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextArea
          v-if="ernStudentData.anyAdditionalInforPriorEnrol"
          :value="ernStudentData.specialCircumstances"
          label="Special circumstances"
          placeholder=" "
          readonly
          class="pr-5"
          counter="2048"
        />
      </v-col>
    </v-row>
    <!-- ROW#2 -->
    <div class="subHeader">
      Students with additional learning and support needs, including disability
    </div>
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(ernStudentData.isSupportForLearningRequired)"
          label="Does the student require support for learning because of disability?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(ernStudentData.areThereModifiStratUsedHome)"
          label="Are there modifications at home that may help us at school to meet the student's educational needs?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextArea
          v-if="ernStudentData.areThereModifiStratUsedHome"
          :value="ernStudentData.modifiStratUsedHome"
          label="Modification details"
          placeholder=" "
          readonly
          class="pr-5"
          counter="2048"
        />
      </v-col>
    </v-row>
    <!-- ROW#3 -->
    <div class="subHeader">Learning adjustments</div>
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="
            yesOrNoWrapper(studentLearningAdjustment.changeToLearningPrograms)
          "
          label="Changes to learning programs and/or teaching strategies?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(studentLearningAdjustment.communication)"
          label="Communication, e.g. speaking and/or listening?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="
            yesOrNoWrapper(studentLearningAdjustment.modificationToEquipment)
          "
          label="Modification to equipment, furniture, learning spaces and/or learning materials?"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <!-- ROW#4 -->
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="
            yesOrNoWrapper(studentLearningAdjustment.supportForPersonalNeeds)
          "
          label="Support for personal care needs, e.g. hygiene, mealtimes and/or health care needs?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(studentLearningAdjustment.socialSupport)"
          label="Social support to engage safely with other children and teachers?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(studentLearningAdjustment.otherAdjustment)"
          label="Other adjustments?"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <!-- ROW#5 -->
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextArea
          v-if="studentLearningAdjustment.otherAdjustment"
          :value="studentLearningAdjustment.otherAdjustmentDetails"
          label="Other adjustment details"
          placeholder=" "
          readonly
          counter="2048"
          class="pr-5"
        />
      </v-col>
    </v-row>
    <!-- ROW#6 -->
    <div class="subHeader">Disabilities</div>
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(studentLearningSupportNeed.autism)"
          label="Autism?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(studentLearningSupportNeed.hearingImpairment)"
          label="A hearing impairment?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(studentLearningSupportNeed.languageDisorder)"
          label="A language disorder?"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <!-- ROW#7 -->
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(studentLearningSupportNeed.physicalDisability)"
          label="A physical disability?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="
            yesOrNoWrapper(studentLearningSupportNeed.difficultiesInLearning)
          "
          label="Difficulties in learning?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(studentLearningSupportNeed.brainInjury)"
          label="Acquired brain injury?"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <!-- ROW#8 -->
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(studentLearningSupportNeed.behaviourDisorder)"
          label="Behaviour disorder?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="
            yesOrNoWrapper(studentLearningSupportNeed.intellectualDisability)
          "
          label="Intellectual disability?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="
            yesOrNoWrapper(studentLearningSupportNeed.mentalHealthDisorder)
          "
          label="Mental health disorder?"
          placeholder=" "
          readonly
        />
      </v-col>
    </v-row>
    <!-- ROW#9 -->
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(studentLearningSupportNeed.visionImpairment)"
          label="A vision impairment?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(studentLearningSupportNeed.otherDisability)"
          label="Other disability?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextArea
          v-if="studentLearningSupportNeed.otherDisability"
          :value="studentLearningSupportNeed.otherDisabilityDetails"
          label="Other disability details"
          placeholder=" "
          readonly
          counter="2048"
          class="pr-5"
        />
      </v-col>
    </v-row>
    <!-- ROW#8 -->
    <div class="subHeader">Support plan</div>
    <v-row>
      <v-col cols="12" md="4">
        <AdsTextField
          :value="yesOrNoWrapper(ernStudentData.hasPrevLearningSupportPlan)"
          label="Has previous learning support plan?"
          placeholder=" "
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <AdsTextArea
          v-if="ernStudentData.hasPrevLearningSupportPlan"
          :value="ernStudentData.prevLearningSupportPlanDetails"
          label="Support plan details"
          placeholder=" "
          readonly
          class="pr-5"
          counter="2048"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AdsTextField, AdsTextArea } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'

export default {
  name: 'SpecialCircumstances',
  components: { AdsTextField, AdsTextArea },
  computed: {
    ...mapGetters({
      selectedErnStudentInfo: 'selectedErnStudentInfo'
    }),
    ernStudentData() {
      return this.selectedErnStudentInfo
    },
    studentLearningAdjustment() {
      return this.ernStudentData.studentLearningAdjustment
        ? this.ernStudentData.studentLearningAdjustment
        : {}
    },
    studentLearningSupportNeed() {
      return this.ernStudentData?.studentLearningSupportNeed
        ? this.ernStudentData.studentLearningSupportNeed
        : {}
    }
  },
  methods: {
    yesOrNoWrapper(value) {
      return value ? 'Yes' : value === false ? 'No' : ''
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-input__slot fieldset {
  border: none;
}

::v-deep .col-md-4,
.col-12 {
  padding: 0 !important;
}
</style>
