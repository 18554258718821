<template>
  <v-list disabled class="flags">
    <v-list-item-group color="primary">
      <v-list-item>
        <v-list-item-icon v-if="hasFlags">
          <v-avatar color="red" size="28">
            <span class="white--text font-weight-bold" aria-hidden="true">
              {{ items.length }}
            </span>
          </v-avatar>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            class="font-weight-bold"
            :class="{ 'ml-3': !hasFlags }"
            aria-hidden="true"
          >
            {{ hasFlags ? '' : 'NO' }} {{ applicationFlagsLabel }}
          </v-list-item-title>
          <div
            class="d-sr-only"
            :aria-label="`${
              hasFlags ? items.length : 'NO'
            } ${applicationFlagsLabel}`"
          />
          <div
            v-for="(flag, i) in flags"
            :key="i"
            class="d-sr-only"
            :aria-label="flag.text"
          />
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-for="(flag, i) in flags" :key="i" class="list-item-flag">
        <v-row class="ml-0 mr-0">
          <v-list-item-icon>
            <v-icon>{{ flag.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-capitalize" aria-hidden="true">{{
              flag.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-row>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'AppFlags',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    flags() {
      const typeToFlag = {
        ORANGE_STOP: { icon: '$orangeFlagStop' },
        ORANGE_EXCLAMATION_MARK: { icon: '$orangeFlagExclamation' },
        ORANGE: { icon: '$orangeFlag' },
        GREY: { icon: '$greyFlag' },
        RED: { icon: '$redFlag' },
        GREEN: { icon: '$greenFlag' },
        GREEN_STRIKE_THROUGH: { icon: '$greenFlagStrikeThrough' },
        YELLOW: { icon: '$yellowFlag' },
        YELLOW_STRIKE_THROUGH: { icon: '$yellowFlagStrikeThrough' },
        BLUE: { icon: '$blueFlag' },
        LIGHT_BLUE: { icon: '$blueFlagLight' },
        PURPLE: { icon: '$purpleFlag' },
        // This has been changed from pink to purple to match ERN
        PINK: { icon: '$purpleFlag' },
        LIME_STRIPE: { icon: '$limeStripeFlag' }
      }
      return this.items.map((f) => ({
        text: f.description,
        icon: typeToFlag[f.type].icon
      }))
    },
    hasFlags() {
      return this.flags.length > 0
    },
    applicationFlagsLabel() {
      return `APPLICATION FLAG${this.items.length === 1 ? '' : 'S'}`
    }
  }
}
</script>

<style scoped lang="scss">
.list-item-flag {
  .v-list-item__content {
    margin-top: -5px;
  }
}
.v-list-item__title {
  white-space: normal;
}
</style>
